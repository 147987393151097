import { Component, createApp } from 'vue';
import { Router } from 'vue-router';
import { VueQueryPlugin } from 'vue-query';
import { globalProperties } from './globalProperties';
import { pinia } from '@/stores';
import { setHTTPHeader } from '@/services/http.service';
import AuthService from '@/services/auth.service';
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import PrimeVue from 'primevue/config';
import Dialog from 'primevue/dialog';
import "primevue/resources/themes/lara-light-blue/theme.css";


const token = AuthService.getToken();

if (token) {
  setHTTPHeader({ Authorization: token });
}

export const setupEntryPoint = (rootComponent: Component, router: Router) => {
  const app = createApp(rootComponent);

  app.use(router);
  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(PrimeVue);
  app.config.globalProperties = {
    ...app.config.globalProperties,
    ...globalProperties,
  };
  app.component('Dialog', Dialog);
  app.component('EasyDataTable', Vue3EasyDataTable);
  app.component('v-select', vSelect);
  app.use(VueSweetalert2);
  app.mount('#app');
};
